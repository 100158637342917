import moment from 'moment';
import axios from 'axios';
import config from './api/api-config';

//let RESERVATION_URL = 'https://nom-back.tide.mx/';

//if( process.env.REACT_APP_BUILD === 'dev' )

export const minReservationDate = ()=>{

    return moment();
};



export const isDateUnavailable = ( date )=>{

    return !!(date.isBefore(minReservationDate(), 'days'))
};

export const isTimeUnavailable=(datetime)=>{
    if(isDateUnavailable(datetime))
        return true;

    return datetime.isBefore(minReservationDate(), 'minutes');
};

export const validateReservation = ( form  )=>{

    if(!form.persona.name)
        return { error: 'Falta agregar nombre'};

    if(!form.persona.patLastName)
        return { error: 'Falta agregar apellido paterno'};

    if(!form.persona.email)
        return {error: 'Falta agregar email'};

    if(!form.persona.birthday || !form.persona.birthday instanceof moment)
        return {error: 'Falta agregar fecha de nacimiento'};

    if (form.acceso === "")
        return {error: 'Selecciona el método de acceso'};

    if(!form.selectedFlights)
        return  {error:'Debes agregar el número de vuelo'};

    return { success: true };

};

/**
 *
 * This function will format dates, booleans and numbers before sending everything to the server
 *
 * @param form
 * @returns {AxiosPromise<any>}
 */
export const uploadReservation = ( form  )=>{

    const { persona, selectedFlights, idTerminal, acceso, suma, comment
    } = form;

        const  params = {
            guest: {
                name: persona.name,
                patLastName: persona.patLastName,
                matLastName: persona.matLastName,
                phone: persona.phone,
                email: persona.email,
                city: persona.city,
                birthday: persona.birthday,
            },
            loungeAccessMethods: [acceso.id],
            terminal: idTerminal,
            personsNumber: suma,
            notes: comment,
            source: "web",
            reservationDate: moment(form.returnReservationDate._d).format( 'YYYY-MM-DDTHH:mm' ),
            flight: selectedFlights.id
        };

    return axios.post( config.host+'/reservations', params );
};
