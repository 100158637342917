import React from 'react';
import {Modal, ModalHeader} from 'reactstrap';

const Terms = ( {isOpen, onClose} )=>(
    <Modal
        isOpen={isOpen}
        toggle={onClose}
        size={'lg'}
        id='aviso-modal'
    >
        <ModalHeader toggle={onClose}>Aviso de privacidad</ModalHeader>
        <div className="modal-body">

            <iframe id="modal-iframe" title="modal-iframe" src="https://tgle.mx/aviso-de-privacidad.html"></iframe>

        </div>

        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>Cerrar</button>
        </div>

    </Modal>
);
export default Terms;
