import React from 'react';
import { Route, Router, Switch} from "react-router-dom";
import apiConfig, {ApiContext} from "./utils/api/api-config";
import {Provider} from "react-redux";
import {createStore} from "redux";
import rootReducer from "./reducers";
import {createBrowserHistory} from "history";
import Api from 'tide-api';
import NotificationAlert from 'react-notification-alert';
import {setComponent as setNotifierComponent} from "../src/utils/hooks/Notifier";
import ReservationForm from "./forms/ReservationForm";

const store = createStore(rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

const hist = createBrowserHistory();

const api = new Api({...apiConfig, reduxStore: store});

const App = () => {
    return (
        <Provider store={store}>
            <ApiContext.Provider value={api}>

                <NotificationAlert ref={setNotifierComponent}/>

                <Router history={hist}>
                    <Switch>
                        <Route path="/" component={ReservationForm}/>
                    </Switch>
                </Router>

            </ApiContext.Provider>
        </Provider>
    );
};

export default App;
