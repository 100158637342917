
//A 'react-notification-alert' got from a ref
let component = null;

//Default notifications options
let options = {
    place: "tr",
    message: '',
    type: "primary",
    autoDismiss: 5
};

//If a notification is sent when there is no component, it is queued until the component is set
let initialQueue = [];

export const setComponent = ( comp )=>{
    component=comp;
    for( let i=0; i<initialQueue.length; i++ )
        component.notificationAlert( initialQueue[i] );

    initialQueue = [];
};

export const notify = {

    success: ( message )=>{
        const opts = {
            ...options,
            message,
            type: 'success',
        };
        notify.custom( opts );
    },

    error: ( message )=>{
        const opts = {
            ...options,
            message,
            type: 'danger',
        };
        notify.custom( opts );
    },

    info: ( message )=>{
        const opts = {
            ...options,
            message,
            type: 'info',
        };
        notify.custom( opts );
    },

    custom: ( options )=>{

        if( !component )
            initialQueue.push( options );
        else
            component.notificationAlert( options );
    }
};

/* ------  Context version --------------
* This version should be initialized on a top level component and sent down throuch react context
*
* DISCLAIMER: ¿Why is this not used? Using react context wouldn't give us the ability to fire
* notifications from code files which are not components, like Api.js
*
* */

/*
import { createContext } from "react";

class Notifier{

    //If a notification is sent when there is no component, it is queued until the component is set
    initialQueue = [];

    //Default notifications options
    options = {
        place: "tr",
        message: '',
        type: "primary",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 5
    };

    //A 'react-notification-alert' got from a ref
    setComponent = ( comp )=>{

        if( !comp ) return;

        this.component=comp;
        for( let i=0; i<this.initialQueue.length; i++ )
            this.component.notificationAlert( this.initialQueue[i] );

        this.initialQueue = [];
    };

    custom = ( options )=>{

        if( !this.component )
            this.initialQueue.push( options );
        else
            this.component.notificationAlert( options );
    };

    success = ( message )=>{
        const opts = {
            ...this.options,
            message,
            type: 'success',
            icon: "tim-icons icon-check-2",
        };
        this.custom( opts );
    };

    error = ( message )=>{
        const opts = {
            ...this.options,
            message,
            type: 'danger',
            icon: "tim-icons icon-alert-circle-exc",
        };
        this.custom( opts );
    };

    info = ( message )=>{
        const opts = {
            ...this.options,
            message,
            type: 'info',
            icon: "tim-icons icon-bell-55",
        };
        this.custom( opts );
    };

}

export default Notifier;

export const context = createContext(null);

 /* ------  End of context version -------------- */
