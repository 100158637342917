import React from 'react'
import Footer from './Footer'
import Facebook from '../assets/img/fb.png'
import Instagram from '../assets/img/in.png'
import Twitter from '../assets/img/tw.png'
import "../assets/css/main.scss";
import PaymentForm from "./PaymentForm";


const ReservationSuccess = ({reservation}) => {

    return (
        <div>
            <div className="thank-you">
                <div className='alert alert-success'>
                    The Grand Lounge Elite te agradece por reservar con nosotros.<br/>
                    Hemos enviado la confirmación a tu correo.
                </div>

                {reservation.prices && reservation.prices.length>0 ?
                    <PaymentForm reservation={reservation}/>
                    : null
                }

                <p>¡No olvides visitarnos en nuestras redes sociales!</p>
                <div>
                    <a href="https://www.facebook.com/thegrandloungeelite/"><img
                        alt="Facebook"
                        className="social-icon"
                        src={Facebook}/></a>
                    <a href="https://twitter.com/GrandLoungeMex">
                        <img alt="Twitter"
                             className="social-icon"
                             src={Twitter}/>
                    </a>
                    <a href="https://www.instagram.com/thegrandloungeelite/">
                        <img alt="Instagram"
                             className="social-icon"
                             src={Instagram}/>
                    </a>
                </div>
                <div className="footer small text-muted">
                    <Footer/>
                </div>
            </div>

        </div>
    )
};
export default ReservationSuccess;
