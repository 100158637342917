import React from 'react';
import {notify} from '../hooks/Notifier';


const errorHandler = (e) => {
    let message = 'Parece que hubo un error.';
    if (e.detail)
        message = <>{message}<br/>{e.detail}</>;
    return notify.error(message);
};

const getHost = () => {
    if (process.env.REACT_APP_BUILD === 'dev')
        return localStorage.host || 'http://localhost:8000';

    if (process.env.REACT_APP_BUILD === 'staging')
        return 'https://gledev.tide.mx';

    if (process.env.REACT_APP_BUILD === 'prod')
        return "https://back.tgle.mx";

};

const config = {
    host: getHost(),
    // commonPath:'api',
    getDataFromResponse: (r) => r.data,
    onError: errorHandler,
    endpoints: [
        'me',
        'user',
        'screen',
        'flights',
        'lounge_access_methods',
        'reservationCardPayments',
        'countries'
    ],
};

export default config;

export const ApiContext = React.createContext(null);
