import React from  'react'

const Footer = () => {

    return(
        <div>
            <p>No se aceptan reservaciones con menos de 5 horas de anticipación.</p>
            <p>El tiempo de tolerancia es de 15 minutos. Nos reservamos el derecho de admisión después de ese tiempo.</p>
            <p>Nos reservamos el derecho de reservar su asiento en cualquiera de nuestras salas disponibles.</p>
            <p>No nos hacemos responsables por el extravío de objetos personales.</p>
            <p>La sala y las experiencias están sujetas a disponibilidad.</p>
        </div>
    )
};
export  default Footer;
