import React, {useState, useEffect, useContext} from 'react'
import "../assets/css/main.scss";
import moneyFormatter from '../utils/moneyFormatter';
import {ApiContext } from "../utils/api/api-config";
import config from "../utils/api/api-config";

import TideEntitySelect from "../components/TideEntitySelect";
import {ToastContainer, toast} from 'react-toastify';
import PaypalImg from '../assets/img/paypal-button.png'

import _ from 'lodash';

const CARD_TAB = 'card_tab';
const PAYPAL_TAB = 'paypal_tab';
const PaymentForm = ({reservation}) => {

    const api = useContext(ApiContext);

    const [error, setError] = useState('');
    const [activeTab /*, setActiveTab*/] = useState(PAYPAL_TAB);
    const [isPayed, setIsPayed] = useState(false);
    const [holderCountry, setHolderCountry] = useState(null);
    const [price, setPrice] = useState(null);
    const [currency, setCurrency] = useState('');

    const [paymentData, setPaymentData] = useState(null);

    const [sendingData, setSendingData] = useState(false);

    /*
    const handleTabChange = (e) => {
        const target = e.currentTarget;
        setActiveTab(target.dataset.tab);
    };
    */

    const handleChangeHolderCountry = (country) => {
        setHolderCountry(country);
        const newPaymentData = {
            ...paymentData,
            country: country.iso2Code
        };
        setPaymentData(newPaymentData);
    };

    const handlePaymentDataChange = (e) => {
        const prop = e.target.dataset.prop;
        const newPaymentData = {...paymentData, [prop]: e.target.value};
        setPaymentData(newPaymentData);
    };

    useEffect(() => {
        window.SrPago.setLiveMode(false);
        window.SrPago.setPublishableKey("pk_dev_5df14c6fc469fyUTdz");
    }, []);

    useEffect(() => {
        const mxnPrice = _.find(reservation.prices, (p) => p.currency === 'MXN');
        const usdPrice = _.find(reservation.prices, (p) => p.currency === 'USD');

        if (activeTab === CARD_TAB && mxnPrice) {
            setPrice(mxnPrice.amount);
            setCurrency('MXN');
        }
        if (activeTab === PAYPAL_TAB && usdPrice) {
            setPrice(usdPrice.amount);
            setCurrency('USD');
        }
    }, [activeTab, reservation]);

    useEffect(() => {
        const newPaymentData = {
            cardNumber: '',
            cvv: '',
            expMonth: '',
            expYear: '',
            holderName: reservation.guest ? reservation.guest.name : reservation.name,
            holderLastName: reservation.guest ? reservation.guest.patLastName : reservation.lastNames
        };
        setPaymentData(newPaymentData);
    }, [reservation]);

    const handleCardSubmit = (e) => {
        e.preventDefault();
        if (!holderCountry) {
            return toast('Es necesario especificar el país', {type: "error"});
        }
        setError(null);
        setSendingData(true);

        const card = {
            number: paymentData.cardNumber,
            holder_name: paymentData.holderName,
            cvv: paymentData.cvv,
            exp_month: paymentData.expMonth,
            exp_year: paymentData.expYear
        };

        const onSuccessHandler = (result) => {
            api.reservationCardPayments.create({
                params: {
                    reservation: reservation.id,
                    token: result.token,
                    paymentData
                }
            }).then(() => {
                setSendingData(false);
                setIsPayed(true);
            }).finally(() => {
                setSendingData(false);
            });

        };
        const onFailHandler = (error) => {
            let message = error.message;

            if (message === 'number') {
                message = 'El número de la tarjeta es inválido';
            } else if (message === 'cardholder_name') {
                message = 'El nombre del tarjetahabietne es inválido';
            } else if (message === 'cvv') {
                message = 'El cvv es inválido';
            }
            setError(message);
            toast(message, {type: "error"});
            setSendingData(false);
        };
        window.SrPago.token.create(card, onSuccessHandler, onFailHandler);
    };

    return (
        <article className="card main-wrapper">
            <ToastContainer/>

            <div className="card-body">
                {!isPayed ?
                    <div>
                        {(!!currency && !!price) ?
                            <div>
                                El servicio tiene un costo de {moneyFormatter(price, activeTab===PAYPAL_TAB?100:1)} {currency}, puedes realizar el
                                pago en
                                la
                                sala
                                o con nuestra forma de pago en linea.
                                <br/>
                                <br/>
                                <ul className="nav bg-light nav-pills rounded nav-fill mb-3" role="tablist">
                                    {/*
                        <li className="nav-item">
                            <button className={"nav-link " + (activeTab === CARD_TAB ? 'active' : '')}
                                    data-tab={CARD_TAB}
                                    onClick={handleTabChange}>
                                <i className="fa fa-credit-card"/> Tarjeta
                            </button>
                        </li>

                            <li className="nav-item">
                            <button className={"nav-link " + (activeTab === PAYPAL_TAB ? 'active' : '')}
                            data-tab={PAYPAL_TAB}
                            onClick={handleTabChange}>
                            <i className="fa fa-paypal"/> Paypal
                            </button>
                            </li>
                        */}
                                </ul>

                                <div className="tab-content">
                                    <div className={"tab-pane fade " + (activeTab === CARD_TAB ? 'show active' : '')}>
                                        {error ? <div className="alert alert-danger">{error}</div> : null}
                                        {paymentData ?
                                            <form onSubmit={handleCardSubmit}>
                                                <h5>Datos de tarjetahabiente</h5>
                                                <hr/>
                                                <div className='row'>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="card-holder-name">Nombre</label>
                                                        <input id="card-holder-name"
                                                               type="text"
                                                               className="form-control"
                                                               data-prop='holderName'
                                                               onChange={handlePaymentDataChange}
                                                               value={paymentData.holderName}
                                                               disabled={sendingData}
                                                               name="card-holder-name"
                                                               required/>
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="card-holder-name">Apellidos</label>
                                                        <input id="card-holder-name"
                                                               type="text"
                                                               className="form-control"
                                                               data-prop='holderLastName'
                                                               onChange={handlePaymentDataChange}
                                                               value={paymentData.holderLastName}
                                                               disabled={sendingData}
                                                               name="card-holder-name"
                                                               required/>
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label htmlFor="card-holder-name">Teléfono</label>
                                                        <input id="card-holder-name"
                                                               type="text"
                                                               className="form-control"
                                                               data-prop='holderPhone'
                                                               onChange={handlePaymentDataChange}
                                                               value={paymentData.holderPhone}
                                                               disabled={sendingData}
                                                               name="card-holder-name"
                                                               required/>
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <label htmlFor="card-holder-name">Dirección</label>
                                                        <input id="card-holder-name"
                                                               type="text"
                                                               className="form-control"
                                                               data-prop='holderAddress'
                                                               onChange={handlePaymentDataChange}
                                                               value={paymentData.holderAddress}
                                                               disabled={sendingData}
                                                               name="card-holder-name"
                                                               required/>
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="card-holder-name">País</label>
                                                        <TideEntitySelect
                                                            entity="countries"
                                                            value={holderCountry}
                                                            onChange={handleChangeHolderCountry}
                                                            labelCreator={(c) => c.spanishName}
                                                            filterLocal
                                                        />
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="card-holder-name">Estado</label>
                                                        <input id="card-holder-name"
                                                               type="text"
                                                               className="form-control"
                                                               data-prop='holderState'
                                                               onChange={handlePaymentDataChange}
                                                               value={paymentData.holderState}
                                                               disabled={sendingData}
                                                               name="card-holder-name"
                                                               required/>
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="card-holder-name">Ciudad</label>
                                                        <input id="card-holder-name"
                                                               type="text"
                                                               className="form-control"
                                                               data-prop='holderCity'
                                                               onChange={handlePaymentDataChange}
                                                               value={paymentData.holderCity}
                                                               disabled={sendingData}
                                                               name="card-holder-name"
                                                               required/>
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="card-holder-name">Código Postal</label>
                                                        <input id="card-holder-name"
                                                               type="text"
                                                               className="form-control"
                                                               data-prop='holderZipCode'
                                                               onChange={handlePaymentDataChange}
                                                               value={paymentData.holderZipCode}
                                                               disabled={sendingData}
                                                               name="card-holder-name"
                                                               required/>
                                                    </div>
                                                </div>
                                                <h5>Datos de tarjeta</h5>
                                                <hr/>
                                                <div className="form-group">
                                                    <label htmlFor="card-number">Número de tarjeta</label>
                                                    <div className="input-group">
                                                        <input required
                                                               id="card-number"
                                                               type="text"
                                                               className="form-control"
                                                               name="card-number"
                                                               data-prop='cardNumber'
                                                               onChange={handlePaymentDataChange}
                                                               value={paymentData.cardNumber}
                                                               disabled={sendingData}
                                                        />
                                                        <div className="input-group-append">
                                            <span className="input-group-text text-muted">
					                            <i className="fa fa-cc-visa"/>  
                                                <i className="fa fa-cc-amex"/>
                                                <i className="fa fa-cc-mastercard"/>
				                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-8">
                                                        <div className="form-group">
                                                            <label><span className="hidden-xs">Expiración</span>
                                                            </label>
                                                            <div className="input-group">
                                                                <input required id="card-exp-month" type="number"
                                                                       className="form-control"
                                                                       placeholder="MM"
                                                                       name="card-exp-month"
                                                                       data-prop='expMonth'
                                                                       onChange={handlePaymentDataChange}
                                                                       value={paymentData.expMonth}
                                                                       disabled={sendingData}
                                                                />
                                                                <input required id="card-exp-year"
                                                                       type="number"
                                                                       className="form-control"
                                                                       placeholder="YY"
                                                                       name="card-exp-year"
                                                                       data-prop='expYear'
                                                                       onChange={handlePaymentDataChange}
                                                                       value={paymentData.expYear}
                                                                       disabled={sendingData}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label data-toggle="tooltip"
                                                                   data-original-title="3 digits code on back side of the card">CVV </label>
                                                            <input
                                                                id="card-cvv"
                                                                type="number"
                                                                className="form-control"
                                                                data-prop='cvv'
                                                                onChange={handlePaymentDataChange}
                                                                value={paymentData.cvv}
                                                                required=""
                                                                disabled={sendingData}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <button className="subscribe btn btn-primary btn-block"
                                                        disabled={sendingData}
                                                        type="submit">
                                                    {sendingData ? 'Pagando...' : 'Pagar'}
                                                </button>
                                            </form> : null}
                                    </div>
                                    <div className={"tab-pane fade " + (activeTab === PAYPAL_TAB ? 'show active' : '')}>
                                        <p> Puedes realizar el pago haciendo clic en el botón:</p>
                                        <p>
                                            <a id="paypal-payment-link"
                                               href={config.host + "/paypal/payment?type=lounge_reservation&reservationId=" + reservation.id}>
                                                <img src={PaypalImg} alt="Paypal button"/>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div> : <h3>Cargando...</h3>
                        }
                    </div> :
                    <h3>Gracias! Hemos recibido su pago.</h3>}
            </div>
        </article>

    );

};

export default PaymentForm;