import React from 'react';
import useCallbackCreator from 'use-callback-creator';


const TimePicker=({value, onChange})=>{

    const handleChangeButton=useCallbackCreator((id)=>{
        const [dir, part]=id.split('|');
        if(!value)
            return;

        const newDate=value.clone().add(dir, part);
        onChange(newDate);
    },[value, onChange]);

    return (
        <div className='TimePicker'>
            <div>
                <button type="button" onClick={handleChangeButton('+1|hours')}>+</button>
                <button type="button" onClick={handleChangeButton('+1|minutes')}>+</button>
            </div>
            <div className='time-display'>
                {value ?
                    `${value.format('HH')}:${value.format('mm')}` :
                    '00:00'}
            </div>
            <div>
                <button type="button" onClick={handleChangeButton('-1|hours')}>-</button>
                <button type="button" onClick={handleChangeButton('-1|minutes')}>-</button>
            </div>
        </div>
    );
};

export default TimePicker;
